<template>
  <div>
    <v-card flat :loading="loadingObj.loading">
      <v-card-text class="pa-0">
          <v-container fluid class="pa-1">
            <v-tabs v-model="activeTab" grow>
              <v-tab class="text-capitalize"> Twillio </v-tab>
              <v-tab class="text-capitalize"> Email </v-tab>
            </v-tabs>
            <v-card-text v-if="activeTab === 0">
              <v-form ref="twillioForm">
                <v-row>
                  <v-col cols="12" lg="4" md="4" sm="4">
                    <v-text-field outlined dense hide-details label="Account Sid" v-model="modelObj.twillio.accountSid"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="4">
                    <v-text-field outlined dense hide-details label="AuthToken" v-model="modelObj.twillio.authToken"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="4">
                    <v-text-field outlined dense hide-details label="Phone Number" v-model="modelObj.twillio.phoneNumber"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="my-2"></v-divider>
              <v-layout class="d-flex justify-center">
                <v-btn color="primary" @click="saveUpdateHandler"> Save </v-btn>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="activeTab === 1">
              <div>
                <div class="cards">
                  <label>
                    <input type="radio" v-model="modelObj.mailbox.provider" value="GOOGLE" checked/>
                    <span class="text">Google </span>
                  </label>
                  <label>
                    <input type="radio" v-model="modelObj.mailbox.provider" value="M365"/>
                    <span class="text">Microsoft</span>
                  </label>
                  <label>
                    <input type="radio" v-model="modelObj.mailbox.provider" value="OTHERS"/>
                    <span class="text">Others</span>
                  </label>
                </div>
              </div>
              <v-form ref="mailboxForm" :disabled="modelObj.mailbox.provider !== 'OTHERS'">
                <v-row class="mt-2">
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-text-field outlined dense hide-details label="UserName" v-model="modelObj.mailbox.username"></v-text-field>
                  </v-col>
                  <template v-if="modelObj.mailbox.provider === 'OTHERS'">
                    <v-col cols="12" lg="4" md="6" sm="6">
                      <v-text-field outlined dense hide-details label="Password" type="password" v-model="modelObj.mailbox.password"></v-text-field>
                    </v-col>
                  </template>
                </v-row>
                <v-row v-if="modelObj.mailbox.provider === 'OTHERS'">
                  <v-col cols="12">
                    <v-card-title class="py-0">Incoming Mail Setting </v-card-title>
                  </v-col>
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-text-field outlined dense hide-details label="Imap Host" v-model="modelObj.mailbox.imapHost"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-text-field type="number" outlined dense hide-details label="Imap Port" v-model.number="modelObj.mailbox.imapPort"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-switch inset outlined dense hide-details label="Use SSL" v-model="modelObj.mailbox.imapUseSSL"></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-card-title class="py-0">Outgoing Mail Setting </v-card-title>
                  </v-col>
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-text-field outlined dense hide-details label="Smtp Host" v-model="modelObj.mailbox.smtpHost"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="6" sm="6">
                    <v-text-field type="number" outlined dense hide-details label="Smtp Port" v-model.number="modelObj.mailbox.smtpPort"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="my-2"></v-divider>
              <v-layout class="d-flex justify-center">
                <v-btn color="primary" @click="ConnectProvider"> Connect </v-btn>
              </v-layout>
            </v-card-text>
          </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loadingObj: {
        loading: false
      },
      activeTab: 0,
      listOfUserRoles: [],
      listOfEmployees: [],
      dialog: false,
      modelObj: {
        mailbox: {},
        twillio: {}
      }
    }
  },
  mounted () {
    window.onmessage = ({ data } = {}) => {
      if (data && data.code) {
        this.saveMailConfiguration(data.authFrom, data.code)
      }
    }
    this.getSettingsHandler()
  },
  methods: {
    getSettingsHandler () {
      this.$_execute('get', 'communication-settings').then(({ data }) => {
        if (!data) data = { }
        if (!data.mailbox) data.mailbox = {}
        if (!data.twillio) data.twillio = {}
        data.mailbox.provider = data.mailbox.provider || 'GOOGLE'
        this.modelObj = data
      })
    },
    saveMailConfiguration (authFrom, code) {
      const url = `communication-settings/connect-email?provider=${authFrom}`
      this.loadingObj.loading = true
      this.$_execute('post', url, (code) ? { code } : this.modelObj.mailbox)
        .then(() => {
          this.$root.$emit('snackbar', { message: (this.modelObj._id) ? 'Updated Successfully' : 'Saved Successfully', color: 'success' })
        })
        .finally(() => {
          this.loadingObj.loading = false
        })
    },
    saveUpdateHandler () {
      if (this.$refs.twillioForm && this.$refs.twillioForm.validate()) {
        this.$_execute('post', `/communication-settings`, this.modelObj).then(() => {
          this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        })
      } else {
        this.$root.$emit('snackbar', { color: 'error', message: 'Please check the required fields!' })
      }
    },
    ConnectProvider () {
      if (this.modelObj.mailbox.provider === 'OTHERS') {
        this.saveMailConfiguration('OTHERS')
      } else {
        let url = ''
        if (this.modelObj.mailbox.provider === 'M365') {
          url = 'microsoft/get-authurl'
        } else if (this.modelObj.mailbox.provider === 'GOOGLE') {
          url = 'google/get-authurl'
        }
        this.$_execute('get', url).then(({ data }) => {
          window.open(data, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
        })
      }
    }
  }
}
</script>
<style scoped>
.cards {
  display: flex;
}
.cards label {
  cursor: pointer;
}
.cards label input {
  display: none;
}
.cards label .text {
  color: #ae2b26;
  font-family: "roboto", arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  height: 90px;
  margin: 0 16px;
  border: 1px solid #f2f2f2;
  background: #f8f8f8;
  padding: 16px;
  text-align: center;
}
.cards label.isActive {
  font-size: 30px;
}
.cards label:hover .text{
  border-color: #ae2b26;
  box-shadow: 0 0 8px #ccc;
}
.cards label input:checked + .text {
  color: green;
  border-color: green;
}
</style>
